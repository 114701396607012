import ko from '../locales/ko'
import en from '../locales/en'
import th from '../locales/th'
import es from '../locales/es'

export default defineI18nConfig(() => ({
  fallbackLocale: 'ko',
  messages: { en, ko, th, es },
  warnHtmlMessage: false,
  warnHtmlInMessage: false,
}))
